import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import { getAPI } from "../utils/Requests";
import { useEffect } from "react";

const useStyles = createUseStyles((theme) => ({
    myBtnMobile: {
        display: "none",
        position: "fixed",
        bottom: 20,
        right: 30,
        zIndex: "9999",
        fontSize: 30,
        border: "none",
        outline: "none",
        background: "#00BFA5",
        color: "white",
        cursor: "pointer",
        padding: 15,
        borderRadius: "50%",
        "&:hover": {
            background: "#00BFA5",
        },
    },
    "@media (max-width: 600px)": {
        myBtnMobile: {
            display: "flex",
        },
    },
}));

function FloatingWaComponent(props) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [contacts, getContacts] = useState([]);

    const getData = () => {
        const url = "setting/detail/3";

        getAPI(url).then((data) => {
            getContacts(data);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={classes.footer}>
            <button
                onClick={() =>
                    openInNewTab(
                        "https://api.whatsapp.com/send?phone=628119212183&text=Halo..."
                    )
                }
                className={classes.myBtnMobile}
            >
                <FaWhatsapp />
            </button>
        </div>
    );
}

export default FloatingWaComponent;
