import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Container, Row, Col, Card } from "react-bootstrap";
import pattern from "../../assets/images/dot.png";
import line from "../../assets/images/line.png";
import CardComponent from "../../components/CardComponent";
import { getAPI } from "../../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    header: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        zIndex: "998",
    },
    insideheader: {
        backgroundImage: `url(${line})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        position: "relative",
        background: theme.color.bluish,
        display: "flex",
        zIndex: "-1",
        minHeight: 400,
    },
    clientimg: {
        background: theme.color.white,
        position: "relative",
        display: "flex",
        height: 350,
        textAlign: "center",
        width: "100%",
        borderRadius: 10,
        boxShadow: "0px 4px 24px rgba(86, 89, 135, 0.15)",
        verticalAlign: "middle",
        margin: "10px 0",
        "& img": {
            textAlign: "center",
            objectFit: "cover",
            borderRadius: 10,
            width: "100%",
            height: "100%",
        },
    },
    text: {
        color: theme.color.fontPrimary,
        padding: "12% 0",
    },
    title: {
        ...theme.fontStyle.title,
        padding: "30px 0",
        color: theme.color.fontPrimary,
    },
    "@media screen and (max-width: 600px)": {
        title: {
            paddingTop: "50px",
        },
    },
}));

function ServiceComponent() {
    let i = 0;
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [services, getServices] = useState([]);

    const getData = () => {
        const url = "post/list?category=2&limit=100";

        getAPI(url).then((data) => {
            getServices(data);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className={classes.header}>
                <div className={classes.insideheader}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={classes.text}>
                                    <h1 className={classes.title}>
                                        Our Service.
                                    </h1>
                                    <p>
                                        PT. Eananta Dwi Lestari not only offers
                                        high quality products but also provides
                                        other services
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <Container>
                    <h2 className={classes.title}>
                        Rent Bicycle & Maintenance
                    </h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "bicycle" && i < 3) {
                                i++;
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                    <h2 className={classes.title}>Electrical Work</h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "pulling" && i < 3) {
                                i++;
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                    <h2 className={classes.title}>Facility Maintenance</h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "facility") {
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                    <h2 className={classes.title}>Rent Car</h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "rent") {
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                    <h2 className={classes.title}>Torishima</h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "torishima") {
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                    <h2 className={classes.title}>SWTS</h2>
                    <Row>
                        <span style={{display:"none"}}>{i = 0}</span>
                        {services.map((data) => {
                            if (data.description == "swts") {
                                return (
                                    <Col md={"4"}>
                                        <div className={classes.clientimg}>
                                            <img src={data.image} />
                                        </div>
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default ServiceComponent;
