const color = {
    primaryColor: "#292D5E",
    fontPrimary: "#565987",
    bluish: "#EAEBF6",
    green: "#0D9322",
    white: "#FFFFFF",
    yellow: "#FFD037",
    dark: "#292D33",
};
const fontStyle = {
    title: {
        fontWeight: "bold",
        color: color.fontPrimary,
    },
    subtitle: {
        color: color.dark,
        opacity: "0.6",
    },
    productTitle: {
        color: color.fontPrimary,
    },
};
const divider = {
    divider: {
        borderTop: "1px solid rgba(41, 45, 94, 0.1)",
        width: "50%",
        display: "flex",
        margin: "auto",
    },
};
const button = {
    button: {
        backgroundColor: color.fontPrimary,
        borderColor: "transparent",
        color: color.white,
        padding: "0 30px",
        lineHeight: "40px",
        "&:hover": {
            backgroundColor: color.bluish,
            color: color.fontPrimary,
            borderColor: "transparent",
        },
        "&:focus": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
        "&:active": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
    },
    buttonSecond: {
        backgroundColor: color.white,
        borderColor: color.fontPrimary,
        border: "2px solid",
        color: color.fontPrimary,
        padding: "0 25px",
        lineHeight: "40px",
        "&:hover": {
            backgroundColor: color.bluish,
            color: color.fontPrimary,
            borderColor: "transparent",
        },
        "&:focus": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
        "&:active": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
    },
    buttonSubmit: {
        backgroundColor: color.yellow,
        borderColor: "transparent",
        color: color.white,
        padding: "0 30px",
        lineHeight: "40px",
        "&:hover": {
            backgroundColor: color.bluish,
            color: color.fontPrimary,
            borderColor: "transparent",
        },
        "&:focus": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
        "&:active": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
    },
    buttonCall: {
        backgroundColor: color.yellow,
        borderColor: "transparent",
        lineHeight: "1px",
        color: color.white,
        fontSize: 24,
        "&:hover": {
            backgroundColor: color.bluish,
            color: color.fontPrimary,
            borderColor: "transparent",
        },
        "&:focus": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
        "&:active": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
    },
    buttonView: {
        backgroundColor: color.fontPrimary,
        borderColor: "transparent",
        color: color.white,
        "&:hover": {
            backgroundColor: color.bluish,
            color: color.fontPrimary,
            borderColor: "transparent",
        },
        "&:focus": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
        "&:active": {
            color: color.primaryColor,
            borderColor: "transparent",
            backgroundColor: color.bluish,
        },
    },
};
export default {
    // https://www.colorhexa.com/A4A6B3
    color,
    button,
    fontStyle,
    divider,
};
