import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Container, Row, Col } from "react-bootstrap";
import pattern from "../../assets/images/dot.png";
import line from "../../assets/images/line.png";
import FadeInSection from "../../components/FadeInComponent";
import { getAPI } from "../../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    header: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        minHeight: 500,
        zIndex: "998",
    },
    insideheader: {
        backgroundImage: `url(${line})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        position: "relative",
        background: theme.color.bluish,
        display: "flex",
        zIndex: "-1",
        minHeight: 400,
    },
    text: {
        color: theme.color.fontPrimary,
        padding: "10% 0",
    },
    title: {
        ...theme.fontStyle.title,
        padding: "30px 0",
        textTransform: "capitalize",
        color: theme.color.fontPrimary,
    },
    "@media screen and (max-width: 600px)": {
        title: {
            paddingTop: "50px",
        },
    },
    client: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom left",
    },
    clientimg: {
        background: theme.color.white,
        position: "relative",
        bottom: 50,
        left: 20,
        display: "table-cell",
        height: 350,
        textAlign: "center",
        width: 300,
        borderRadius: 10,
        boxShadow: "0px 4px 24px rgba(86, 89, 135, 0.15)",
        verticalAlign: "middle",
        "& img": {
            textAlign: "center",
            objectFit: "contain",
            width: 200,
            height: 100,
        },
    },
    clientbox: {
        margin: "80px 0",
        borderRadius: 10,
        background: theme.color.bluish,
    },
    clienttext: {
        color: theme.color.fontPrimary,
        padding: "30px 20px",
    },
}));

function ClientComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [clients, getClients] = useState([]);

    const getData = () => {
        const url = "post/list?category=1";

        getAPI(url).then((data) => {
            getClients(data);
        });
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <div className={classes.header}>
                <div className={classes.insideheader}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={classes.text}>
                                    <h1 className={classes.title}>
                                        Our Client.
                                    </h1>
                                    <p>
                                        PT. Eananta Dwi Lestari has satisfied
                                        and loyal clients who use our services
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={classes.client}>
                <Container>
                    {clients.map((client) => (
                        <FadeInSection>
                            <div className={classes.clientbox}>
                                <Row>
                                    <Col md={"4"}>
                                        <FadeInSection>
                                            <div className={classes.clientimg}>
                                                <img src={client.image} />
                                            </div>
                                        </FadeInSection>
                                    </Col>
                                    <Col>
                                        <div className={classes.clienttext}>
                                            <FadeInSection direction="left">
                                                <h2 className={classes.title}>
                                                    {client.title}
                                                </h2>
                                                <p>{client.description}</p>
                                            </FadeInSection>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </FadeInSection>
                    ))}
                </Container>
            </div>
        </>
    );
}

export default ClientComponent;
