import React from "react";
import {
    arrayOf,
    element,
    func,
    number,
    oneOfType,
    shape,
    string,
} from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { Card, Col, Button, Row } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";

const useStyles = createUseStyles((theme) => ({
    col: {
        display: "flex",
        width: "100%",
    },
    cardStyle: {
        backgroundColor: theme.color.white,
        margin: "0 0 1.7rem 0",
        borderRadius: 10,
        border: "none",
        width: "100%",
        boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
        "& .card-header": {
            padding: "0",
        },
        "& .card-body": {
            padding: "10px 5px 10px 5px",
        },
        "& .card-footer": {
            padding: ".75rem 0.25rem",
            backgroundColor: "none",
            borderTop: "none",
        },
    },
    iconStyle: {
        color: "#000",
        width: 30,
        height: 30,
        padding: 5,
        borderRadius: 5,
        marginRight: 10,
        boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
    },
    title: {
        ...theme.fontStyle.productTitle,
    },
    description: {
        fontSize: 14,
        ...theme.fontStyle.subtitle,
    },
    button: {
        ...theme.button.button,
        background: theme.color.primaryColor,
        width: "100%",
    },
    image: {
        position: "relative",
        background: theme.color.dark,
        borderRadius: 10,
        height: 200,
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        width: "100%",
        transition: ".5s ease",
    },
    text: {
        background: theme.color.primaryColor,
        padding: 10,
        borderRadius: 10,
        position: "absolute",
        fontSize: 14,
        color: "#ffffff",
        bottom: 8,
        left: 16,
    },
    btnGroup: {
        width: "100%",
        display: "flex",
        padding: "0 10px",
    },
    view: {
        width: "80%",
        marginRight: 5,
        alignItems: "center",
        justifyContent: "center",
        ...theme.button.buttonView,
    },
    call: {
        width: "20%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...theme.button.buttonCall,
    },
    pad: {
        paddingBottom: 5,
    },
}));

function CardComponent(props) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const {
        title,
        category,
        link,
        wa,
        image,
        large,
        small,
        medium,
        items,
        addButton,
    } = props;
    function renderItem(item, index) {
        return (
            <Col className={classes.itemContainer} key={`item-${index}`}>
                {item}
            </Col>
        );
    }

    return (
        <Col lg={large} xs={small} md={medium} className={classes.col}>
            <Card className={classes.cardStyle}>
                <Card.Header>
                    <div className={classes.image}>
                        <img src={image} />
                        <div className={classes.overlay}>
                            {category ? (
                                <div className={classes.text}>{category}</div>
                            ) : null}
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>{items.map(renderItem)}</Card.Body>
                <Card.Footer>
                    <div className={classes.btnGroup}>
                        {addButton ? (
                            <Button
                                href={link}
                                className={classes.view}
                                target="_blank"
                            >
                                View Product
                            </Button>
                        ) : null}
                        {addButton ? (
                            <Button
                                href={wa}
                                target="_link"
                                className={classes.call}
                            >
                                <FaWhatsapp />
                            </Button>
                        ) : null}
                        {/* <Row>
                        {addButton ? (
                            <Col xs={12} lg={8} className={classes.pad}>
                                <Button
                                    href={link}
                                    className={classes.view}
                                >
                                    View Product
                                </Button>
                            </Col>
                        ) : null}
                        {addButton ? (
                            <Col xs={12} lg={4} className={classes.pad}>
                                <Button
                                    href={link}
                                    className={classes.call}
                                >
                                    <FaWhatsapp />
                                </Button>
                            </Col>
                        ) : null}
                    </Row> */}
                    </div>
                </Card.Footer>
            </Card>
        </Col>
    );
}

CardComponent.propTypes = {
    title: oneOfType([string, element]),
    category: oneOfType([string, element]),
    description: oneOfType([string, element]),
    link: func,
    large: number,
    medium: number,
    small: number,
    wa: oneOfType([string, element]),
};

export default CardComponent;
