import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Container, Col, Row, Button, Tabs, Tab, Card } from "react-bootstrap";
import FadeInSection from "../../components/FadeInComponent";
import line from "../../assets/images/line.png";
import pattern from "../../assets/images/dotgrid.png";
import patternright from "../../assets/images/dot.png";
import { getAPI } from "../../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    hideMobile: {
        right: 0,
    },
    divider: {
        ...theme.divider.divider,
    },
    header: {
        display: "flex",
        height: "100vh",
        backgroundImage: `url(${pattern}),url(${line})`,
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "bottom left,top left",
        "& .container-fluid": {
            padding: 0,
        },
    },
    textblock: {
        padding: "40px 0",
    },
    title: {
        ...theme.fontStyle.title,
    },
    subtitle: {
        ...theme.fontStyle.subtitle,
    },
    imgheader: {
        // width:'50%',
        "& img": {
            height: "100vh",
            width: "100%",
            objectFit: "cover",
            float: "right",
            borderBottomLeftRadius: 30,
            zIndex: "-1",
            boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
        },
    },
    dot: {
        position: "absolute",
        left: "10%",
        bottom: "0",
    },
    text: {
        color: theme.color.fontPrimary,
        height: "100%",
        margin: "5rem 0",
        padding: "10rem 5rem 0 10rem",
        "& h1": {
            fontWeight: "bold",
            fontSize: "3.5em",
        },
    },
    button: {
        ...theme.button.button,
    },

    industries: {
        // background:theme.color.bluish,
        backgroundImage: `url(${patternright})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        minHeight: 100,
        marginTop: 100,
        padding: "50px 0",
    },
    image: {
        position: "relative",
        background: theme.color.dark,
        height: 200,
        borderRadius: 10,
        margin: "15px 0",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 10,
        },
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        width: "100%",
        transition: ".5s ease",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: 10,
    },
    textoverlay: {
        padding: 10,
        borderRadius: 10,
        position: "absolute",
        fontSize: 14,
        color: "#ffffff",
        bottom: 8,
        left: 16,
    },
    client: {
        cursor: "pointer",
        padding: 30,
        height: "10em",
        display: "flex",
        alignItems: "center",
        "& img": {
            filter: "grayscale(100%)",
        },
        "& img:hover": {
            filter: "grayscale(0%)",
        },
    },
    titleproduct: {
        ...theme.fontStyle.productTitle,
    },
    description: {
        fontSize: 14,
        ...theme.fontStyle.subtitle,
    },
    "@media (max-width: 600px)": {
        title: {
            paddingTop: "50px",
        },
        text: {
            margin: "2rem 0",
            padding: "5rem 15px 0 15px",
        },
        client: {
            padding: 0,
        },
        hideMobile: {
            display: "none",
        },
    },
}));

function HomeComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [coverage, getCoverage] = useState([]);
    const [clients, getClients] = useState([]);

    const getDataClient = () => {
        const url = "post/list?category=1";

        getAPI(url).then((data) => {
            getClients(data);
        });
    };

    const getData = () => {
        const url = "post/list?category=6";

        getAPI(url).then((data) => {
            getCoverage(data);
        });
    };

    useEffect(() => {
        getData();
        getDataClient();
    }, []);
    return (
        <>
            <div className={classes.header}>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className={classes.text}>
                                <h1>Solution for Industries.</h1>
                                <p>
                                    PT. Eananta Dwi Lestari committed to the
                                    environment protection and to ensuring the
                                    health and safety of all employees.
                                </p>
                                <Button className={classes.button} href="/about">
                                    Learn More
                                </Button>
                            </div>
                        </Col>
                        <Col className={classes.hideMobile}>
                            <div className={classes.imgheader}>
                                <img
                                    src={
                                        require("../../assets/images/header.jpg")
                                            .default
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={classes.industries}>
                <Container>
                    <Row>
                        <Col>
                            <div className={classes.textblock}>
                                <h2 className={classes.title}>
                                    Industries Coverage
                                </h2>
                                <span className={classes.subtitle}>
                                    The scope of the industries we have worked
                                    on or are currently working on and manage
                                </span>
                            </div>
                        </Col>
                    </Row>
                    {coverage.map((data) => (
                        <div key={data.id}>
                            <Row>
                                <Col md={{ span: 10, offset: 1 }}>
                                    <FadeInSection>
                                        <div className={classes.image}>
                                            <img
                                                src={
                                                    require("../../assets/images/" +
                                                        data.image).default
                                                }
                                            />
                                            <div className={classes.overlay}>
                                                <div
                                                    className={
                                                        classes.textoverlay
                                                    }
                                                >
                                                    <h4>{data.title}</h4>
                                                    <p>{data.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </FadeInSection>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Container>
            </div>
            <div className={classes.divider}></div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <div className={classes.textblock}>
                                <h2 className={classes.title}>Our Client</h2>
                                <span className={classes.subtitle}>
                                    Clients who have used the services of
                                    Eananta
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {clients.map((data) => (
                            <Col key={data.id}>
                                <div className={classes.client}>
                                    <img src={data.image} width="100%" />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomeComponent;
