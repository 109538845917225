import React, { Component } from 'react';

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const direction=props;
  const domRef = React.useRef();
  React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  setVisible(entry.isIntersecting);
              }
          });
      });
      observer.observe(domRef.current);
  }, []);
  return (
      <div
          className={`fade-in-section ${ props.direction } ${isVisible ? 'is-visible' : ''}`}
          ref={domRef}
      >
          {props.children}
      </div>
  );
}

FadeInSection.defaultProps={
    direction:'top',
}
export default FadeInSection;