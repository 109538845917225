import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import pattern from "../../assets/images/dotgrid.png";
import { Form, Col, Button, Container, Row, Image } from "react-bootstrap";
import {
    HiOutlineMail,
    BiPhone,
    AiOutlineInstagram,
    FaFacebookF,
    AiOutlineTwitter,
} from "react-icons/all";
import Tokped from "../../assets/images/tokped.png";
import FadeInSection from "../../components/FadeInComponent";
import { getAPI } from "../../utils/Requests";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
    header: {
        display: "flex",
    },
    textblock: {
        padding: "40px 0",
    },
    text: {
        color: theme.color.fontPrimary,
        height: "100%",
        margin: "5rem 0",
        padding: "10rem 0rem 0 10rem",
        "& h1": {
            fontWeight: "bold",
        },
    },
    "@media screen and (max-width: 600px)": {
        text: {
            padding: "0",
        },
        rightblock: {
            "& $text": {
                padding: "0 15px !important",
            },
        },
    },
    dot: {
        position: "absolute",
        left: "10%",
        bottom: "0",
        zIndex: -1,
    },
    right:{
        background: theme.color.dark,
        boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
    },
    rightblock: {
        width: "100%",
        float: "right",
        zIndex: "-1",
        "& $text": {
            color: theme.color.white,
            padding: "10rem 10rem 0 5rem",
        },
    },
    form: {
        "& .form-control": {
            background: theme.color.bluish,
            margin: "5px 0",
        },
        "& .form-row": {
            marginBottom: 20,
        },
    },
    btnSubmit: {
        ...theme.button.buttonSubmit,
        width: "40%",
        float: "right",
    },
    maps: {
        marginBottom: "-36px",
        "& iframe": {
            border: 0,
        },
    },
    socmed: {
        position: "absolute",
        bottom: 0,
    },
    textlist: {
        display: "flex",
        padding: "10px 0",
        width: "100%",
    },
    list: {
        color: theme.color.white,
        listStyle: "none",
        float: "left",
        display: "inline",
        "& li": {
            fontSize: 24,
            float: "left",
            display: "inline",
            margin: "0 5px",
            padding: "3px 2px",
        },
    },
    horizontalList: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        float: "left",
        display: "inline",
        li: {
            float: "left",
            display: "inline",
            margin: "0 5px",
            padding: "3px 2px",
        },
        "& li ul": {
            float: "right",
        },
    },
}));

function ContactComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [contacts, getContacts] = useState([]);

    const getData = () => {
        const url = "setting/list";

        getAPI(url).then((data) => {
            getContacts(data);
        });
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <div className={classes.header}>
                <Container fluid>
                    <Row>
                        <Col lg={7} xs={12}>
                            <div className={classes.text}>
                                <h1>Get In Touch</h1>
                                <Form className={classes.form}>
                                    <Form.Row>
                                        <Col lg={6} xs={12}>
                                            <Form.Control
                                                type="text"
                                                placeholder="John"
                                            />
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Form.Control
                                                type="email"
                                                placeholder="youremail@gmail.com"
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                placeholder="Hi Enanta, i want to ask something ..."
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Button className={classes.btnSubmit}>
                                        Send
                                    </Button>
                                </Form>
                                <img className={classes.dot} src={pattern} />
                            </div>
                        </Col>

                        <Col lg={5} xs={12} className={classes.right}>
                            <FadeInSection direction="left">
                                <div className={classes.rightblock}>
                                    <div className={classes.text}>
                                        <h4 className={classes.title}>
                                            Address
                                        </h4>
                                        {contacts.map((contact) => (
                                            contact.key == "address_one" || contact.key == "address_two" || contact.key == "address_three" ? 
                                            <div key={contact.id} className={classes.textlist}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: contact.value,
                                                    }}
                                                />
                                            </div>
                                            : ""
                                        ))}
                                    </div>
                                    <div className={classes.socmed}>
                                        <ul className={classes.list}>
                                            <li>
                                                <FaFacebookF />
                                            </li>
                                            <li>
                                                <AiOutlineInstagram />
                                            </li>
                                            <li>
                                                <AiOutlineTwitter />
                                            </li>
                                            <li>
                                                <a href="https://www.tokopedia.com/eananta" target="_blank"><Image src={Tokped}  /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </FadeInSection>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={classes.maps}>
                {contacts.map((contact) => (
                    contact.key == "maps" ?
                        <span key={contact.id}
                            dangerouslySetInnerHTML={{
                                __html: contact.value,
                            }}
                        />
                    : ""
                ))}
            </div>
        </>
    );
}

export default ContactComponent;