import React from "react";
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    test:{
        background: theme.color.yellow,
        height:100,
    }
}))

function ProductDetailComponent(){
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div className="product-detail">
            <div></div>
        </div>
    );
}
export default ProductDetailComponent;
