import React, { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Accordion,
    AccordionContext,
    useAccordionToggle,
} from "react-bootstrap";
import CardComponent from "../../components/CardComponent";
import {
    AiOutlineCaretUp,
    AiOutlineCaretDown,
    FaSort,
    BiSortAZ,
} from "react-icons/all";
import { getAPI } from "../../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    header: {
        background: theme.color.bluish,
        minHeight: 100,
    },
    product: {
        marginTop: 30,
    },
    btnSearch: {
        width: "100%",
        ...theme.button.button,
    },
    btnFilter: {
        width: "100%",
        ...theme.button.buttonSecond,
    },
    topbar: {
        marginBottom: 30,
    },
    category: {
        background: theme.color.primaryColor,
        borderRadius: 10,
        "& h5": {
            textAlign: "center",
            padding: 20,
            cursor: "pointer",
            color: theme.color.white,
        },
        "& .card": {
            cursor: "pointer",
            background: theme.color.bluish,
            padding: "0.75rem 0.25rem",
            borderRadius: 0,
            "& .card-body": {
                padding: "0.25rem",
            },
            "& span": {
                justifyContent: "space-between",
                display: "flex",
                "& svg": {
                    position: "relative",
                    top: 5,
                    bottom: 5,
                },
            },
        },
    },
    listItem: {
        color: theme.color.primaryColor,
        margin: 0,
        padding: "0 0 0 10px",
        cursor: "pointer",
        listStyle: "none",
        "& li:hover": {
            opacity: "0.5",
        },
    },
    title: {
        ...theme.fontStyle.productTitle,
    },
    description: {
        fontSize: 14,
        ...theme.fontStyle.subtitle,
    },
}));

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <span onClick={decoratedOnClick}>
            {children}{" "}
            {isCurrentEventKey ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        </span>
    );
}

function ProductComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [products, getProducts] = useState([]);
    const [categories, getCategories] = useState([]);
    const [search, setSearch] = useState("");

    const getData = (type = 0) => {
        var url = "product/list?search=" + search + "&category=" + type;

        if (type == "reset") {
            url = "product/list";
        }

        getAPI(url).then((data) => {
            getProducts(data);
        });
    };

    const getDataCategory = () => {
        const url = "category/list?type=product&limit=100&sort=asc";

        getAPI(url).then((data) => {
            getCategories(data);
        });
    };

    const reset = () => {
        setSearch("");
        getData("reset");
    };

    const catClick = (val) => {
        getData(val);
    };

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const capitalize = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        getData();
        getDataCategory();
    }, []);

    return (
        <>
            <div className={classes.header}></div>
            <div className={classes.product}>
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            <div className={classes.category}>
                                <h5>Category</h5>
                                <Accordion defaultActiveKey="0">
                                    {categories.map((category) => {
                                        if (category.parent_id == null) {
                                            return (
                                                <Card>
                                                    <ContextAwareToggle
                                                        as={Card.Header}
                                                        eventKey={category.id}
                                                    >
                                                        {capitalize(category.title)}
                                                    </ContextAwareToggle>
                                                    <Accordion.Collapse
                                                        eventKey={category.id}
                                                    >
                                                        <Card.Body>
                                                            <ul
                                                                className={
                                                                    classes.listItem
                                                                }
                                                            >
                                                                {categories.map(
                                                                    (cat) => {
                                                                        if (
                                                                            cat.parent_id ==
                                                                                category.id &&
                                                                            cat.is_parent !=
                                                                                1
                                                                        ) {
                                                                            if (
                                                                                cat.url !=
                                                                                ""
                                                                            ) {
                                                                                return (
                                                                                    <li>
                                                                                        <a
                                                                                            href={
                                                                                                cat.url
                                                                                            }
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                capitalize(cat.title)
                                                                                            }
                                                                                        </a>
                                                                                    </li>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <li >
                                                                                        <span key={cat.id} onClick={() => catClick(cat.id)}>
                                                                                        {
                                                                                            capitalize(cat.title)
                                                                                        }</span>
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        } else if (
                                                                            cat.parent_id ==
                                                                                category.id &&
                                                                            cat.is_parent ==
                                                                                1
                                                                        ) {
                                                                            return (
                                                                                <Accordion defaultActiveKey="0">
                                                                                    <ContextAwareToggle
                                                                                        as={
                                                                                            Card.Header
                                                                                        }
                                                                                        eventKey={
                                                                                            cat.id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            capitalize(cat.title)
                                                                                        }
                                                                                    </ContextAwareToggle>
                                                                                    <Accordion.Collapse
                                                                                        eventKey={
                                                                                            cat.id
                                                                                        }
                                                                                    >
                                                                                        <Card.Body>
                                                                                            <ul
                                                                                                className={
                                                                                                    classes.listItem
                                                                                                }
                                                                                            >
                                                                                                {categories.map(
                                                                                                    (
                                                                                                        ct
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            ct.parent_id ==
                                                                                                            cat.id
                                                                                                        ) {
                                                                                                            if (
                                                                                                                ct.url !=
                                                                                                                ""
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            href={
                                                                                                                                ct.url
                                                                                                                            }
                                                                                                                            target="_blank"
                                                                                                                        >
                                                                                                                            {
                                                                                                                                capitalize(ct.title)
                                                                                                                            }
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                );
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <li>
                                                                                                                        <span
                                                                                                                            key={
                                                                                                                                ct.id
                                                                                                                            }
                                                                                                                            onClick={() =>
                                                                                                                                catClick(
                                                                                                                                    ct.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                capitalize(ct.title)
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </li>
                                                                                                                );
                                                                                                            }
                                                                                                        } else {
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </ul>
                                                                                        </Card.Body>
                                                                                    </Accordion.Collapse>
                                                                                </Accordion>
                                                                            );
                                                                        } else {
                                                                        }
                                                                    }
                                                                )}
                                                            </ul>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            );
                                        }
                                    })}
                                </Accordion>
                            </div>
                        </Col>
                        <Col>
                            <div className={classes.topbar}>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Form>
                                            <Form.Row>
                                                <Col md={"8"}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="hammer, wrench, socket set"
                                                        style={{
                                                            height: "100%",
                                                        }}
                                                        onChange={handleChange}
                                                        value={search}
                                                    />
                                                </Col>
                                                <Col md={"2"}>
                                                    <Button
                                                        className={
                                                            classes.btnSearch
                                                        }
                                                        onClick={getData}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                                <Col md={"2"}>
                                                    <Button
                                                        className={
                                                            classes.btnFilter
                                                        }
                                                        onClick={reset}
                                                    >
                                                        Reset
                                                    </Button>
                                                </Col>
                                            </Form.Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                {products.map((product) => (
                                    <CardComponent
                                        image={product.image}
                                        link={product.url}
                                        wa={
                                            "https://api.whatsapp.com/send?phone=628119212183&text=Hi, saya mau tanya produk " +
                                            product.title
                                        }
                                        large={3}
                                        medium={3}
                                        small={12}
                                        addButton={true}
                                        category={product.category.title}
                                        items={[
                                            <>
                                                <Card.Title>
                                                    <span
                                                        className={
                                                            classes.titleproduct
                                                        }
                                                    >
                                                        {product.title}
                                                    </span>
                                                </Card.Title>
                                                <Card.Text>
                                                    <p
                                                        className={
                                                            classes.description
                                                        }
                                                    >
                                                        {product.description.substring(
                                                            0,
                                                            100
                                                        ) + "..."}
                                                    </p>
                                                </Card.Text>
                                            </>,
                                        ]}
                                    />
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default ProductComponent;
