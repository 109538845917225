import React, { useEffect } from "react";
import {
    Navbar,
    Nav,
    Container,
    Image,
    Button,
    NavDropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../assets/css/navbar.css";
import { createUseStyles, useTheme } from "react-jss";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../assets/images/logo.png";
import { useState } from "react";
import { getAPI } from "../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    navbar: {
        position: "absolute",
        width: "100%",
        zIndex: "998",
    },
    navlink: {
        fontWeight: "600",
        color: theme.color.fontPrimary,
        margin: "0 15px",
        "&:hover": {
            color: theme.color.yellow,
            textDecoration: "none",
        },
    },
    active: {
        color: theme.color.primaryColor,
        fontWeight: "bold",
    },
    wabutton: {
        background: "#00BFA5",
        border: "none",
        display: "inline-flex",
        alignItems: "center",
        boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
        padding: "10px 20px",
        "&:hover": {
            background: theme.color.primaryColor,
        },
        "&:active": {
            background: theme.color.primaryColor,
        },
        "&:focus": {
            backgroundColor: "#00BFA5",
            borderColor: "#00BFA5",
            boxShadow: "0 0 0 0.2rem rgba(0, 191, 165, / 50%)",
        },
        "& svg": {
            marginRight: 10,
            fontSize: 20,
        },
    },
    flatButton: {
        "& #myBtn": {
            position: "fixed",
            display: "none",
            bottom: 40,
            right: 40,
            zIndex: "99",
            fontSize: 30,
            border: "none",
            outline: "none",
            background: "#00BFA5",
            color: "white",
            cursor: "pointer",
            padding: 15,
            borderRadius: "50%",
            "&:hover": {
                background: "#00BFA5",
            },
        },
    },
    "@media (max-width: 600px)": {
        navbar: {
            "& .navbar-toggler": {
                borderColor: "transparent",
            },
            "& .navbar-collapse": {
                background: theme.color.white,
                padding: 15,
                borderRadius: 20,
                boxShadow: "0px 8px 24px rgba(86, 89, 135, 0.15)",
            },
        },
        navlink: {
            margin: "10px 0",
        },
        wabutton: {
            width: "100%",
        },
        flatButton: {
            "& #myBtn": {
                display: "none",
            },
        },
        myBtnMobile: {
            display: "flex",
        },
    },
}));
function NavbarComponent (props) {
    // const logo = require("../assets/images/test.png");
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [expanded, setExpanded] = useState(false);
    const [contacts, getContacts] = useState();

    const getData = () => {
        const url = "setting/detail/3";

        getAPI(url).then((data) => {
            var url = "https://api.whatsapp.com/send?phone="+ data.value +"&text=Hi Eananta, saya mau tanya";
            getContacts(url);
        });
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        var mybutton = document.getElementById("myBtn");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function () {
            scrollFunction();
        };

        function scrollFunction() {
            if (
                document.body.scrollTop > 20 ||
                document.documentElement.scrollTop > 20
            ) {
                mybutton.style.display = "flex";
            } else {
                mybutton.style.display = "none";
            }
        }
        getData();
    }, []);

    return (
        <div className="nav-border">
            <Navbar className={classes.navbar} expanded={expanded} expand="lg">
                <Container className="custom-toggler">
                    <Navbar.Brand href="/">
                        <Image
                            className="d-inline-block align-top"
                            src={logo}
                            height="60"
                        />
                        {/* Eananta */}
                    </Navbar.Brand>
                    <Navbar.Toggle
                        onClick={() => setExpanded(expanded ? false : true)}
                        aria-controls="responsive-navbar-nav"
                    />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav
                            onClick={() => setExpanded(expanded ? false : true)}
                            defaultActiveKey="/"
                            className="mr-auto center-img"
                        >
                            <NavLink
                                className={classes.navlink}
                                exact
                                activeClassName={classes.active}
                                to="/about"
                            >
                                About Us
                            </NavLink>
                            <NavLink
                                className={classes.navlink}
                                exact
                                activeClassName={classes.active}
                                to="/product"
                            >
                                Product
                            </NavLink>
                            <NavLink
                                className={classes.navlink}
                                exact
                                activeClassName={classes.active}
                                to="/client"
                            >
                                Client
                            </NavLink>
                            <NavLink
                                className={classes.navlink}
                                exact
                                activeClassName={classes.active}
                                to="/service"
                            >
                                Service
                            </NavLink>
                            <NavLink
                                className={classes.navlink}
                                exact
                                activeClassName={classes.active}
                                to="/contact"
                            >
                                Contact
                            </NavLink>
                        </Nav>
                        <div className={classes.button}>
                            <Button
                                onClick={() => 
                                    openInNewTab(
                                        contacts
                                    )
                                }
                                className={classes.wabutton}
                            >
                                <FaWhatsapp />
                                WhatsApp
                            </Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
                <div className={classes.flatButton}>
                    <button
                        onClick={() =>
                            openInNewTab(
                                contacts
                            )
                        }
                        id="myBtn"
                        className="myBtn"
                    >
                        <FaWhatsapp />
                    </button>
                </div>
            </Navbar>
        </div>
    );
};

export default NavbarComponent;
