import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Container, Row, Col, Image } from "react-bootstrap";
import pattern from "../../assets/images/dot.png";
import line from "../../assets/images/line.png";
import { FaQuoteLeft } from "react-icons/fa";
import FadeInSection from "../../components/FadeInComponent";

const useStyles = createUseStyles((theme) => ({
    divider: {
        borderTop: "1px solid rgba(41, 45, 94, 0.1)",
        width: "100%",
    },
    dividercenter: {
        ...theme.divider.divider,
    },
    avatar: {
        borderRadius:'10px',
        width: "100%",
        height: 400,
        objectFit: "cover",
        objectPosition: "left",
    },
    vector: {
        width: "70%",
    },
    header: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        minHeight: 500,
        zIndex: "998",
    },
    insideheader: {
        backgroundImage: `url(${line})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        position: "relative",
        background: theme.color.bluish,
        display: "flex",
        zIndex: "-1",
        minHeight: 400,
    },
    text: {
        color: theme.color.fontPrimary,
        padding: "12% 0",
        "& h1": {
            fontSize: "3.5em",
        },
    },
    textbox: {
        "& h2": {
            fontSize: "2.5em",
        },
        "& h5": {
            fontWeight:'600',
            color: theme.color.fontPrimary,
        },
    },
    title: {
        ...theme.fontStyle.title,
        padding: "30px 0",
        color: theme.color.fontPrimary,
    },
    "@media screen and (max-width: 600px)": {
        title: {
            paddingTop: "50px",
        },
    },
    content: {
        padding: "30px 0 0 0",
        display: "flex",
        "& p": {
            fontSize: "1.5em",
        },
    },
    visi: {
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom left",
        minHeight: 500,
        zIndex: "998",
    },
    icon: {
        width: 150,
        fontSize: 30,
        color: theme.color.primaryColor,
    },
    about: {
        background: theme.color.bluish,
        padding: "50px 0",
        margin: "50px 0",
    },
    right: {
        float: "right",
    },
    center: {
        textAlign: "center",
    },
    industries: {
        minHeight: 100,
        marginTop: 50,
        padding: "50px 0",
    },
    image: {
        position: "relative",
        background: theme.color.dark,
        borderRadius: 10,
        height: 80,
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 10,
        },
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        width: "100%",
        transition: ".5s ease",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: 10,
    },
    textoverlay: {
        width: "100%",
        textAlign: "center",
        padding: 10,
        borderRadius: 10,
        position: "absolute",
        fontSize: 16,
        color: "#ffffff",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    group: {
        paddingTop: 30,
        minHeight: 500,
        marginBottom: "-30px",
    },
    groupcontent: {
        textAlign: "center",
        paddingTop: 30,
        "& img": {
            height: 100,
        },
        "& h5": {
            color: theme.color.fontPrimary,
            textTransform: "capitalize",
            fontWeight: "bold",
            paddingTop: 15,
        },
    },
    "@media (max-width: 600px)": {
        avatar: {
            width: "100%",
        },
        vector: {
            width: "100%",
        },
        visi: {
            backgroundImage: "none",
        },
    },
}));

function AboutComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <>
            <div className={classes.header}>
                <div className={classes.insideheader}>
                    <Container>
                        <Row>
                            <Col>
                                <div className={classes.text}>
                                    <h1 className={classes.title}>
                                        Solution for Industries.
                                    </h1>
                                    <p>
                                        PT. Eananta Dwi Lestari committed to the
                                        environment protection and to ensuring
                                        the health and safety of all employees
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <Container>
                    <h2 className={classes.title}>Sambutan Direksi</h2>
                    <Row>
                        <Col
                            md={{ span: 6, order: "first" }}
                            xs={{ span: 12, order: "last" }}
                        >
                            <div className={classes.textbox}>
                                <b>Abednego Tombe</b> as director has more than 30 years practical experiences as the engineering, operation maintenance and sales marketing in various companies such as Beyond Petroleum (BP), Siemens Indonesia, PT. TRIPATRA EPC, mainly for Oil & Gas and Industries.
                            </div>
                        </Col>
                        <Col
                            md={{ span: 6, order: "last" }}
                            xs={{ span: 12, order: "first" }}
                        >
                            <Image
                                className={[classes.right, classes.avatar].join(
                                    " "
                                )}
                                src={
                                    require("../../assets/images/abnego.jpeg")
                                        .default
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={classes.about}>
                <Container>
                    <Row>
                        <Col md={"6"} xs={"12"}>
                            <div className={classes.textbox}>
                                <FadeInSection>
                                    <h2 className={classes.title}>
                                        Who We Are
                                    </h2>
                                </FadeInSection>
                                <FadeInSection>
                                    <p>
                                        PT Eananta Dwi Lestari established in 2014 to serve growing investment climate for Oil & Gas and Petrochemicals Industries Kabupaten Banggai – Central of Sulawesi
                                    </p>
                                    <p>
                                        Our goal is to empowering  local resources  in order to align with customers need for high quality and competitive  products and services. We work closely with international companies and do add value work to serve customers satisfaction.
                                    </p>
                                </FadeInSection>
                            </div>
                        </Col>
                        <Col md={"6"} xs={"12"}>
                            <FadeInSection direction="left">
                                <Image
                                    className={[
                                        classes.right,
                                        classes.vector,
                                    ].join(" ")}
                                    src={
                                        require("../../assets/images/vector.png")
                                            .default
                                    }
                                />
                            </FadeInSection>
                        </Col>
                    </Row>
                </Container>
                <div className={classes.visi}>
                    <Container>
                        <Row>
                            <Col md={"5"}>
                                <FadeInSection direction="right">
                                    <div className={classes.textbox}>
                                        <h2 className={classes.title}>
                                            Our <br />
                                            Vission
                                        </h2>
                                        <div className={classes.divider}></div>
                                        <div className={classes.content}>
                                            <span className={classes.icon}>
                                                <FaQuoteLeft />
                                            </span>
                                            <p>
                                                Our vision is to be the most reliable company in Sulawesi, where customers can find Industrial products & solution.
                                            </p>
                                        </div>
                                    </div>
                                </FadeInSection>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 4 }}>
                                <FadeInSection direction="left">
                                    <div className={classes.textbox}>
                                        <h2 className={classes.title}>
                                            Mission
                                        </h2>
                                        <div className={classes.divider}></div>
                                        <div className={classes.content}>
                                            <p>
                                                Build strong relation with Partners to Strengthen our  Internal Resources to achieve customer satisfaction products & solution.
                                            </p>
                                        </div>
                                    </div>
                                </FadeInSection>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={classes.group}>
                <Container>
                    <Row>
                        <Col>
                            <h2 className={classes.title}>Group eananta</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={classes.groupcontent}>
                                <img
                                    src={
                                        require("../../assets/images/tbp.png")
                                            .default
                                    }
                                />
                                <div className={classes.textgroup}>
                                    <h5>PT tunas Batui Pratama</h5>
                                    <p>
                                        Transportation, Meintenance,
                                        construction, Man Power Supply,
                                        Petrochemicals
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={classes.groupcontent}>
                                <img
                                    src={
                                        require("../../assets/images/sumber.png")
                                            .default
                                    }
                                />
                                <div className={classes.textgroup}>
                                    <h5>PT Sumber Solusi Teknik</h5>
                                    <p>
                                        Oil Gas and mining, telecomm system,
                                        mechanical & electrical, security & fire
                                        system
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AboutComponent;
