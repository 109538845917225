import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NavbarComponent from "./components/NavbarComponent";
import FooterComponent from "./components/FooterComponent";
import HomeComponent from "./routes/home/HomeComponent";
import AboutComponent from "./routes/about/AboutComponent";
import ProductComponent from "./routes/product/ProductComponent";
import ProductDetailComponent from "./routes/product/ProductDetailComponent";
import ClientComponent from "./routes/client/ClientComponent";
import ContactComponent from "./routes/contact/ContactComponent";
import ServiceComponent from "./routes/service/ServiceComponent";
import theme from "./resources/theme";
import { ThemeProvider } from "react-jss";
import FloatingWaComponent from "./components/FloatingWaComponent";

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <NavbarComponent />
                    <Switch>
                        <Route exact path="/" component={HomeComponent} />
                        <Route path="/about" component={AboutComponent} />
                        <Route path="/product" component={ProductComponent} />
                        <Route
                            path="/product/:id"
                            component={ProductDetailComponent}
                        />
                        <Route path="/client" component={ClientComponent} />
                        <Route path="/service" component={ServiceComponent} />
                        <Route path="/contact" component={ContactComponent} />
                    </Switch>
                    <FooterComponent />
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
