import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Container, Row, Col } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail, BiPhone } from "react-icons/all";
import { useState } from "react";
import { useEffect } from "react";
import { getAPI } from "../utils/Requests";

const useStyles = createUseStyles((theme) => ({
    footer: {
        background: theme.color.primaryColor,
        marginTop: 30,
        padding: "50px 0 10px 0",
        minHeight: 100,
    },
    title: {
        fontWeight: "bold",
        color: "#fff",
    },
    foottext: {
        color: theme.color.white,
        padding: "10px 0",
    },
    copyright: {
        color: theme.color.white,
        padding: "50px 0 0 0 ",
        textAlign: "center",
    },
    list: {
        display: "flex",
        padding: "10px 0",
        width: "100%",
    },
    horizontalList: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        float: "left",
        display: "inline",
        li: {
            float: "left",
            display: "inline",
            margin: "0 5px",
            padding: "3px 2px",
        },
        "& li ul": {
            float: "right",
        },
    },
}));

function FooterComponent(props) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [contacts, getContacts] = useState([]);

    const getData = () => {
        const url = "setting/list";

        getAPI(url).then((data) => {
            getContacts(data);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={classes.footer}>
            <Container>
                <Row>
                    <Col md={6}>
                            <h4 className={classes.title}>Address</h4>
                        {contacts.map((contact) => (
                            contact.key == "address_three" ? 
                            <div key={contact.id} className={classes.foottext}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: contact.value,
                                    }}
                                />
                            </div> : "")
                        )};
                    </Col>
                    <Col>
                        <div className={classes.foottext}>
                            <h4 className={classes.title}>Contact Us</h4>
                            <div className={classes.list}>
                                <ul className={classes.horizontalList}>
                                    <li>
                                        <HiOutlineMail /> Email
                                        <br />
                                        <ul
                                            style={{
                                                paddingInlineStart: "20px",
                                            }}
                                        >

                                            {contacts.map((contact) => (
                                                contact.key == "email_one" || contact.key == "email_two" ? 
                                                <li key={contact.id}>
                                                    {contact.value}
                                                </li> : "" )
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={classes.copyright}>
                            <span>
                                {contacts.map((contact) => (
                                    contact.key == "footer" ? 
                                    <span key={contact.id}
                                        dangerouslySetInnerHTML={{
                                            __html: contact.value,
                                        }}
                                    /> : "" )
                                )}
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FooterComponent;
